import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import meQuery from "~/query/meQuery";
import { useNavigate } from "~/router";
import { authStateAtom } from "~/state";

const NavigationHooks = () => {
  const [delayed, setDelayed] = useState(false);
  const authState = useAtomValue(authStateAtom);
  const navigate = useNavigate();

  // Automatically redirect if there is not user data
  const { data, isLoading } = useQuery(meQuery());

  // This is a major hack. The problem is, that the atoms are not loading
  // properly as they are not async. Feel free to remove this, when that is
  // fixed.
  useEffect(() => {
    setTimeout(() => {
      setDelayed(true);
    }, 500);
  }, []);

  // Navigation effect on auth state
  useEffect(() => {
    if (!delayed) {
      return;
    }

    // We are definitely not authenticated, stop execution
    if (!authState) {
      return;
    }

    // we are logged in, redirect to frontpage
    if (!isLoading && data?.me) {
      navigate("/dashboard");
    }
  }, [authState, delayed, data, isLoading, navigate]);

  return null;
};

export default function Layout() {
  return (
    <>
      <NavigationHooks />
      <Outlet />
    </>
  );
}
