import classNames from "classnames";
import { FC, MouseEventHandler, ReactElement } from "react";
import Spinner from "./Spinner";

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const Button: FC<{
  children: ReactElement | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  variant?: "PRIMARY" | "SECONDARY" | "TERTIARY" | "DESTRUCTIVE" | "TEXT_ONLY";
  size?: "BIG" | "DEFAULT" | "SMALL" | "XS";
  className?: string;
  dataTestId?: string;
}> = ({
  children,
  onClick,
  disabled,
  loading,
  type,
  variant = "PRIMARY",
  size = "DEFAULT",
  className,
  dataTestId,
}) => {
  return (
    <button
      data-test-id={dataTestId}
      type={type}
      className={classNames(
        "relative",
        {
          "button-primary": variant === "PRIMARY",
          "button-secondary": variant === "SECONDARY",
          "button-primary bg-gray-300": variant === "TERTIARY",
          "button-primary bg-error": variant === "DESTRUCTIVE",
          "button-base text-primary-blue font-medium": variant === "TEXT_ONLY",
          "w-40": size === "BIG",
          "text-sm": size === "BIG" || size === "DEFAULT",
          "text-xs": size === "SMALL",
          "text-xs px-4 h-6": size === "XS",
          "opacity-40 hover:opacity-40 cursor-not-allowed": disabled || loading,
        },
        className
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {!loading ? (
        <div className="m-0">{children}</div>
      ) : (
        <div className="flex absolute top-0 right-0 bottom-0 left-0 justify-center">
          <Spinner className="w-4" />
        </div>
      )}
    </button>
  );
};

export default Button;
