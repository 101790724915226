import { DriveFileMoveOutlined } from "@mui/icons-material";
import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Tooltip } from "@thedealersconcierge/components";
import { CdkDealJacketStatus } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { TransactionStatus } from "~/__generated__/backend/zeus";
import { default as pushDocumentsToCdkAction } from "~/actions/documents/pushDocumentsToCdkAction";
import pushFormSubmissionsToCdkAction from "~/actions/formSubmissions/pushFormSubmissionsToCdkAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import FileErrorIcon from "~/components/icons/FileErrorIcon";
import { getReadableFormSubmissionType } from "~/lib/enumReadable";
import { queryClient } from "~/lib/query";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { viewDocumentQuery } from "./_queries";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const LocationStateSchema = z.object({
  documentIds: z.array(z.string()).nullable().optional(),
});

// This page expects documentIds passed from location.state
// is only responsible to render out the document
// any conditionals or filters should be handled out of this page
const DealJacketViewDocumentPage = () => {
  const { t } = useTranslation();
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/deal-jacket/document/:documentId"
  );

  const location = useLocation();
  const locationState = LocationStateSchema.parse(location.state);
  const documentIds = locationState.documentIds ?? [];

  const dealership = useAtomValue(dealershipAtom);

  const { data } = useQuery(viewDocumentQuery(transactionId, documentIds));

  const [numPages, setNumPages] = useState<{ [key: string]: number }>({});
  const [isPushingToDms, setIsPushingToDms] = useState(false);

  const handleDocumentLoadSuccess =
    (url: string) =>
    ({ numPages }: { numPages: number }) => {
      setNumPages((prev) => ({ ...prev, [url]: numPages }));
    };

  const transaction = data?.transaction;

  // We know the id is unique, we just don't know who owns it
  const documents = [
    ...(transaction?.buyer?.documents?.edges ?? []),
    ...(transaction?.coBuyer?.documents?.edges ?? []),
  ];

  const formSubmissions = [
    ...(transaction?.buyer?.formSubmissions?.edges ?? []),
    ...(transaction?.coBuyer?.formSubmissions?.edges ?? []),
  ];

  const formSubmissionUrls = formSubmissions.map((el) => el.node);
  const documentUrls = documents.map((el) => el.node);
  const urls = [...formSubmissionUrls, ...documentUrls];

  const getTitle = useCallback(() => {
    if (documentIds.length > 1) return `View Document (${documentIds.length})`;

    if (formSubmissions.length === 1) {
      const n = formSubmissions.at(0)?.node;
      const title = n?.form?.displayTitle ?? "Document";
      const typeStr = n?.type ?? "Document";

      if (["PRE_PURCHASE", "POST_PURCHASE"].includes(typeStr)) {
        return title;
      }
      return getReadableFormSubmissionType(typeStr);
    }
    if (documents.length === 1) {
      return documents.at(0)?.node?.title ?? "Document";
    }
    return "View Document";
  }, [formSubmissions.length]);

  const handlePushToDms = async () => {
    try {
      setIsPushingToDms(true);

      await pushFormSubmissionsToCdkAction(
        formSubmissions
          .filter((fs) => fs.node?.id)
          .map((fs) => fs.node?.id ?? "should-never-happen")
      );
      await pushDocumentsToCdkAction(
        documents
          .filter((d) => d.node?.id)
          .map((d) => d.node?.id ?? "should-never-happen")
      );
      await queryClient.resetQueries({
        queryKey: ["transaction-document", transactionId],
      });

      toast.success(t("Pushed to DMS"));
    } catch (error: unknown) {
      captureException(error);
      toast.error(
        t(
          "Failed to push to DMS. Please contact support to make sure the dealership is setup correctly."
        )
      );
    } finally {
      setIsPushingToDms(false);
    }
  };

  const stagedDocumentExists = !!urls.find(
    (u) =>
      u?.cdkDealJacketStatus &&
      CdkDealJacketStatus.parse(u.cdkDealJacketStatus) === "STAGED_FOR_PUSHING"
  );
  const allDocumentsPushed = !urls.find(
    (u) =>
      !u?.cdkDealJacketStatus ||
      CdkDealJacketStatus.parse(u.cdkDealJacketStatus) !== "PUSHED"
  );
  const cdkDmsDealId = data?.transaction?.cdkDmsDealId;
  const hasEnabledPostPurchaseDocs =
    data?.transaction?.dealership?.hasEnabledPostPurchaseDocs;
  const disablePushToDms =
    // Transaction status check for typing
    !data?.transaction?.status ||
    // The CDK DMS deal ID has to be set
    !cdkDmsDealId ||
    // Cannot push again while a document is staged and the push is in progress
    stagedDocumentExists ||
    // When the dealership uses post purchase docs, then the transaction has to be "Delivered" in order to push the docs to the DMS
    (hasEnabledPostPurchaseDocs &&
      data.transaction.status !== TransactionStatus.DELIVERED) ||
    // When the dealership does not use post purchase docs, then the transaction has to be either "Ready for F&I" or "Delivered"
    (!hasEnabledPostPurchaseDocs &&
      ![TransactionStatus.READY_FOR_FNI, TransactionStatus.DELIVERED].includes(
        data.transaction.status
      ));

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <BreadCrumbsContainer>
          <BreadCrumb title="Transaction">
            <Link to={"/dashboard"}>{t("Transactions")}</Link>
          </BreadCrumb>

          <BreadCrumb title="User">
            <Link
              to={"/dashboard/transaction/:transactionId"}
              params={{
                transactionId,
              }}
            >
              {transaction?.title}
            </Link>
          </BreadCrumb>

          <BreadCrumb title="Deal Jacket">
            <Link
              to={"/dashboard/transaction/:transactionId/deal-jacket"}
              params={{
                transactionId,
              }}
            >
              {t("Deal Jacket")}
            </Link>
          </BreadCrumb>

          <BreadCrumb title={getTitle()} />
        </BreadCrumbsContainer>
      </div>

      <div className="flex flex-1 flex-row items-start justify-center">
        <div className="flex flex-col w-full max-w-screen-md items-center overflow-scroll">
          {urls.length > 0 ? (
            urls.map((url, index) => (
              <Fragment key={index}>
                {!url?.file?.url && (
                  <div className="flex flex-col space-y-2">
                    {t(
                      "Failed to load document. This could be because it has not yet been filled out by the customer"
                    )}
                  </div>
                )}
                {url?.file?.url && (
                  <Document
                    file={url.file.url}
                    onLoadSuccess={handleDocumentLoadSuccess(url.file.url)}
                    className="flex flex-col space-y-2"
                    loading={
                      <div className="flex w-full min-h-[80vh] justify-center items-center">
                        <Spinner />
                      </div>
                    }
                    error={
                      <div className="flex flex-col w-full min-h-[80vh] justify-center items-center">
                        <div className="flex flex-col space-y-4 items-center">
                          <div className="relative">
                            <FileErrorIcon className="w-20 text-dark-gray" />
                          </div>

                          <div className="text-subtitle text-dark-gray">
                            {t("Failed to load document")}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {[...new Array(numPages[url.file.url] || 0)].map(
                      (_, pageIndex) => (
                        <Page
                          key={pageIndex}
                          pageNumber={pageIndex + 1}
                          className="border border-very-light-gray"
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      )
                    )}
                  </Document>
                )}
              </Fragment>
            ))
          ) : (
            <div className="flex w-1/3 aspect-square items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>

        {data?.transaction?.dealership?.hasEnabledCdkDms &&
          [
            // Maybe it makes sense to share these rules in the TDC package?
            "ADMIN",
            "FNI_MANAGER",
            "SALES_MANAGER",
          ].includes(dealership?.activeDealershipPerms.role ?? "") && (
            <div className="space-y-4">
              <Tooltip
                anchor={
                  <div>
                    <Button
                      label={t("Push to DMS")}
                      onClick={() => void handlePushToDms()}
                      icon={<DriveFileMoveOutlined />}
                      variant="SECONDARY"
                      size="MEDIUM"
                      isLoading={isPushingToDms}
                      disabled={disablePushToDms}
                    />
                  </div>
                }
                hide={!disablePushToDms}
                content={
                  <p>
                    {stagedDocumentExists
                      ? t("Push in progress")
                      : allDocumentsPushed
                        ? t("Pushed to DMS")
                        : !cdkDmsDealId
                          ? t("The Deal Number is not set")
                          : hasEnabledPostPurchaseDocs
                            ? t("Transaction needs to be 'Delivered'")
                            : t("Transaction needs to be 'Ready for F&I'")}
                  </p>
                }
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default DealJacketViewDocumentPage;
