import { z } from "zod";
import { nullableObject } from "../util";

export const VehiclePrincipalPriorUse = z.union([
  z.literal("PRIVATE_PASSENGER_VEHICLE", {
    description: "Private Passenger Vehicle",
  }),
  z.literal("RENTAL_VEHICLE", { description: "Rental Vehicle" }),
  z.literal("TAXI", { description: "Taxi" }),
  z.literal("LIVERY_VEHICLE", { description: "Livery Vehicle" }),
  z.literal("COMMERCIAL", { description: "Commercial" }),
  z.literal("FLEET", { description: "Fleet" }),
  z.literal("DEMO", { description: "Demo" }),
  z.literal("OTHER", { description: "Other" }),
]);

export type VehiclePrincipalPriorUse = z.infer<typeof VehiclePrincipalPriorUse>;

export const VehicleCondition = z.union([
  z.literal("EXCELLENT", { description: "Excellent" }),
  z.literal("GOOD", { description: "Good" }),
  z.literal("SATISFACTORY", { description: "Satisfactory" }),
  z.literal("UNSATISFACTORY", { description: "Unsatisfactory" }),
]);

export type VehicleCondition = z.infer<typeof VehicleCondition>;

// Only partially implemented, feel free to add in more fields as long
// as they are congruent with the prisma model
export const VehicleSchema = z.object({
  id: z.string().uuid(),
  vin: z.string(),
  make: z.string(),
  model: z.string(),
  year: z.string(),
  registrationCardId: z.string().uuid(),
  registrationName: z.string(),
  registrationState: z.string(),
  bodyType: z.string(),
  condition: z.string(),
  color: z.string(),
  mileage: z.number(),
  isUsed: z.boolean(),
  trim: z.string(),
  stockNumber: z.string(),
  principalPriorUse: VehiclePrincipalPriorUse,
  pictureFrontId: z.string().uuid(),
  pictureBackId: z.string().uuid(),
  pictureLeftSideId: z.string().uuid(),
  pictureRightSideId: z.string().uuid(),
  pictureInteriorId: z.string().uuid(),
  pictureOdometerId: z.string().uuid(),
  pictureVinNumberId: z.string().uuid(),
});

export type VehicleSchema = z.TypeOf<typeof VehicleSchema>;

export const CrmVehicleSchema = VehicleSchema.pick({
  year: true,
}).merge(
  nullableObject(
    VehicleSchema.pick({
      make: true,
      model: true,
      vin: true,
      stockNumber: true,
      trim: true,
      bodyType: true,
      mileage: true,
      isUsed: true,
    }).partial()
  )
);

export type CrmVehicleSchema = z.TypeOf<typeof CrmVehicleSchema>;
