import { captureException } from "@sentry/react";
import { gqlMutationClient } from "~/lib/backend";

const pushDocumentsToCdkAction = async (documentIds: string[]) => {
  try {
    return await gqlMutationClient()({
      pushDocumentsToCdk: [
        {
          ids: documentIds,
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationPushDocumentsToCdkSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });
  } catch (e) {
    console.error(e);
    captureException(e, { extra: { documentIds } });
  }
};

export default pushDocumentsToCdkAction;
