import { useQuery } from "@tanstack/react-query";
import { X } from "react-feather";
import { Selector } from "~/__generated__/backend/zeus";
import Modal from "~/components/Modal";
import DocumentIcon from "~/components/icons/DocumentIcon";
import { gqlQueryClient } from "~/lib/backend";
import { useNavigate, useParams } from "~/router";
import Header from "../[transactionId]/lending-portal/_components/Header";

const selector = (transactionId: string) =>
  Selector("Query")({
    transaction: [
      {
        id: transactionId,
      },
      {
        id: true,
        title: true,
        buyer: {
          prequalApplications: [
            { first: 1 },
            {
              edges: {
                node: {
                  newestReportPrequalify: {
                    reportHtml: true,
                  },
                },
              },
            },
          ],
        },
      },
    ],
  });

const CreditReportModal = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");

  const handleBack = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };

  const { data } = useQuery({
    queryKey: ["transaction-credit-report", transactionId],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: async (context) => gqlQueryClient()(selector(context.queryKey[1])),
  });

  /**
   * This operation performed to change the FIXED Height of 580 in the custom report IFrame Response
   *
   * To ensure this operation performed as safe as possible we only target the height property with very specific values
   */
  const html = data?.transaction?.buyer?.prequalApplications?.edges
    ?.at(0)
    ?.node?.newestReportPrequalify?.reportHtml?.replace(
      'HEIGHT="580"',
      'HEIGHT="100%"'
    );

  const dataUrl = `data:text/html;base64,${btoa(html ?? "")}`;

  return (
    <Modal
      title="Buyer Credit Report"
      isOpen
      onClose={close}
      isShowTitle={false}
    >
      <div className="flex flex-col h-dvh w-dvw">
        <Header
          title={
            <div className="flex flex-row gap-4 items-center">
              <DocumentIcon className="size-4 icon-primary" />
              <p className="text-heading-2">700 Credit Report</p>
            </div>
          }
          rightElement={
            <button
              onClick={handleBack}
              disabled={false}
              className="px-4 justify-center items-center"
            >
              <X />
            </button>
          }
        />

        <div className="flex flex-col h-full w-full justify-center items-center">
          <div className="desktop-screen-grid h-full w-full">
            {html ? (
              <div className="col-span-12 mb-16">
                <iframe
                  src={dataUrl}
                  className="w-full h-full transform scale-110 origin-top-center mt-16"
                ></iframe>
              </div>
            ) : (
              <div className="flex justify-center col-span-12 p-4">
                No Report
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreditReportModal;
