import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import {
  FinanceType,
  TransactionSource as TransactionSourceSchema,
  TransactionStatusSchema,
} from "@thedealersconcierge/lib/codecs/tdc";
import {
  getHumanReadableTransactionSource,
  getReadableTransactionStatus,
} from "@thedealersconcierge/lib/codecs/util";
import classNames from "classnames";
import { format } from "date-fns";
import { useAtomValue } from "jotai";
import stringify from "json-stable-stringify";
import debounce from "lodash/debounce";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  Role,
  TransactionSource,
  TransactionStatus,
} from "~/__generated__/backend/zeus";
import Button from "~/components/Button";
import DataIndicator from "~/components/DataIndicator";
import Spinner from "~/components/Spinner";
import AssignButton from "~/components/StaffButton/AssignButton";
import StatusBadge from "~/components/badges/StatusBadge";
import TransactionSourceBadge from "~/components/badges/TransactionSourceBadge";
import TextInput from "~/components/design-system-components/input/TextInput";
import CheckMarkIcon from "~/components/icons/CheckMarkIcon";
import ChevronRightIcon from "~/components/icons/ChevronRightIcon";
import CircleLineThroughIcon from "~/components/icons/CircleLineThroughIcon";
import CrossIcon from "~/components/icons/CrossIcon";
import MagnifierIcon from "~/components/icons/MagnifierIcon";
import DropdownCheckbox, {
  DropdownCheckboxOption,
} from "~/components/inputs/DropdownCheckbox";
import config from "~/config";
import { gqlQueryClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import {
  Pagination,
  Row,
  dataTransform,
  formatDealType,
  selector,
  transformOrdering,
} from "./_dashboardUtils";
import { userQuery } from "./users/_queries/userQuery";

const statusSchemaToGraphQlEnum = (
  e: TransactionStatusSchema
): TransactionStatus => {
  switch (e) {
    case "REGISTERED":
      return TransactionStatus.REGISTERED;
    case "SIGNED_UP":
      return TransactionStatus.SIGNED_UP;
    case "IN_PROGRESS":
      return TransactionStatus.IN_PROGRESS;

    case "PREQUAL_SUBMITTED":
      return TransactionStatus.PREQUAL_SUBMITTED;
    case "CREDIT_APP_SUBMITTED":
      return TransactionStatus.CREDIT_APP_SUBMITTED;
    case "FILE_COMPLETE":
      return TransactionStatus.FILE_COMPLETE;
    case "READY_FOR_FNI":
      return TransactionStatus.READY_FOR_FNI;

    case "DELIVERED":
      return TransactionStatus.DELIVERED;

    // Deprecated
    case "WEB_LEAD":
      return TransactionStatus.WEB_LEAD;
    case "WEB_APPLICATION":
      return TransactionStatus.WEB_APPLICATION;
    case "COMPLETED": {
      return TransactionStatus.COMPLETED;
    }
    case "DEAD": {
      return TransactionStatus.DEAD;
    }
    case "DISASSOCIATED": {
      return TransactionStatus.DISASSOCIATED;
    }
    case "RULE_90_DAYS": {
      return TransactionStatus.RULE_90_DAYS;
    }
    case "NOT_STARTED": {
      return TransactionStatus.NOT_STARTED;
    }
  }
};

const sourceSchemaToGraphQlEnum = (
  s: TransactionSourceSchema
): TransactionSource => {
  switch (s) {
    case "ADMIN_DASHBOARD":
      return TransactionSource.ADMIN_DASHBOARD;
    case "KIOSK":
      return TransactionSource.KIOSK;
    case "WEB_APPLICATION":
      return TransactionSource.WEB_APPLICATION;
    case "WEB_PREQUAL":
      return TransactionSource.WEB_PREQUAL;
  }
};

export default function Dashboard() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<TransactionStatus[]>([]);
  const [sourceFilter, setSourceFilter] = useState<TransactionSource[]>([]);
  const [assigedStaffIdsFilter, setAssigedStaffIdsFilter] = useState<string[]>(
    []
  );
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    pageSize: 10,
    direction: "after",
  });
  const navigate = useNavigate();
  const dealership = useAtomValue(dealershipAtom);

  // This is a hack. Please avoid this construction anywhere else
  useEffect(() => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
  }, [sorting]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchString = useCallback(
    debounce((term: string) => {
      setSearchString(term);
      setPage((p) => ({
        ...p,
        currentPage: 1,
        cursor: undefined,
        direction: "after",
      }));
    }, 500),
    []
  );

  const qKey = [
    "dashboard_table",
    dealership?.activeDealershipPerms.dealershipId,
    stringify(transformOrdering(sorting)),
    stringify(page),
    searchString,
    stringify(statusFilter),
    stringify(sourceFilter),
    stringify(assigedStaffIdsFilter),
  ];

  const { data: tableData, isLoading: loading } = useQuery({
    queryKey: qKey,
    enabled: Boolean(dealership?.activeDealershipPerms.dealershipId),
    queryFn: () => {
      if (dealership?.activeDealershipPerms.dealershipId) {
        return gqlQueryClient()(
          selector(
            dealership.activeDealershipPerms.dealershipId,
            transformOrdering(sorting),
            page,
            searchString,
            statusFilter,
            sourceFilter,
            assigedStaffIdsFilter
          )
        );
      }
    },
  });
  const reloadList = async () => {
    // We refetch everything - this is akin to reloading the the browser
    await queryClient.refetchQueries();
  };

  const trns = tableData?.dealership?.transactions?.edges ?? [];

  // Permissions
  const canViewDealJacket = permissionChecker(
    "viewDealJacket",
    dealership?.activeDealershipPerms
  );

  const totalCount = tableData?.dealership?.transactions?.totalCount ?? 0;
  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      //  Sticky headers
      {
        header: () => <span>Date</span>,
        id: "creationDate",
        accessorFn: (row) => <>{format(row.creationDate, "MM/dd/yyyy")}</>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.creationDate.getTime() - b.original.creationDate.getTime(),
      },
      {
        header: () => <span>Last Activity</span>,
        id: "lastActivityDate",
        accessorFn: (row) => <>{format(row.lastActivityDate, "MM/dd/yyyy")}</>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.lastActivityDate.getTime() -
          b.original.lastActivityDate.getTime(),
      },
      {
        header: () => <span>First Name</span>,
        id: "firstName",
        accessorFn: (row) => (
          <div className="flex justify-start w-full">
            {row.firstName ?? "-"}
          </div>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.firstName?.localeCompare(b.original.firstName ?? "") ?? 0,
      },
      {
        header: () => <span>Last Name</span>,
        id: "lastName",
        accessorFn: (row) => (
          <div className="flex justify-start w-full">{row.lastName ?? "-"}</div>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.lastName?.localeCompare(b.original.lastName ?? "") ?? 0,
      },

      // The rest
      {
        header: () => <span>Status</span>,
        id: "status",
        accessorFn: (row) => <StatusBadge status={row.status} size="SMALL" />,
        size: 200,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) => a.original.status.localeCompare(b.original.status),
      },
      {
        header: () => <span>Source</span>,
        id: "source",
        accessorFn: (row) => (
          <TransactionSourceBadge source={row.source} size="SMALL" />
        ),
        size: 200,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) => a.original.source.localeCompare(b.original.source),
      },
      {
        header: () => <span>Co-Buyer</span>,
        id: "co-buyer",
        size: 128,
        accessorFn: (row) => (
          <DataIndicator
            val={
              row.hasCoBuyer
                ? !!row.coBuyerUserId
                : row.coBuyerUserId
                  ? Boolean(row.coBuyerUserId)
                  : undefined
            } // If the customer specified they have a co-buyer but the co-buyer isn't connected yet, we show a red cross
          />
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>ID</span>,
        id: "identifacation",
        accessorFn: (row) => (
          <DataIndicator
            val={row.firstIdCard ? Boolean(row.firstIdCard) : undefined}
          />
        ),
        cell: (info) => info.getValue(),
        size: 80,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>Prequal</span>,
        accessorKey: "prequal",
        accessorFn: (row) => (
          <DataIndicator
            val={
              row.buyerHasPrequalApplications
                ? true
                : row.buyerWantsToPrequalify
                  ? false
                  : undefined
            }
          />
        ),
        cell: (info) => info.getValue(),
        size: 80,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>Credit</span>,
        accessorKey: "credit",
        accessorFn: (row) => (
          <DataIndicator val={row.buyerHasHardCreditApplications} />
        ),
        cell: (info) => info.getValue(),
        size: 80,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>Deal Jacket</span>,
        id: "deal-jacket",
        accessorFn: (row) => {
          return (
            <>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    "/dashboard/transaction/:transactionId/deal-jacket",
                    {
                      params: {
                        transactionId: row.transactionId,
                      },
                    }
                  );
                }}
                variant="TERTIARY"
              >
                View
              </Button>
            </>
          );
        },
        cell: (info) => info.getValue(),
        size: 144,
        footer: (props) => props.column.id,
        enableSorting: false,
      },

      {
        header: () => <span>Trade</span>,
        id: "trade",
        accessorFn: (row) => {
          return (
            <DataIndicator
              val={
                row.buyerHasTradeVehicle
                  ? !!row.tradeVehicle
                  : row.tradeVehicle
                    ? Boolean(row.tradeVehicle)
                    : undefined
              }
            />
          );
        },
        cell: (info) => info.getValue(),
        size: 96,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>Vehicle</span>,
        id: "vehicle",
        accessorFn: (row) => {
          if (row.vehicle)
            return <div className="line-clamp-1">{row.vehicle}</div>;
          return "-";
        },
        size: 264,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>Deal Type</span>,
        id: "deal-type",
        accessorFn: (row) => {
          return (
            <span>
              {row.financeType
                ? formatDealType(FinanceType.parse(row.financeType))
                : ""}
            </span>
          );
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        header: () => <span>Sales Person</span>,
        id: "sales-person",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 216,
        accessorFn: (row) => {
          if (row.salesPerson) {
            return <div className="line-clamp-1">{row.salesPerson}</div>;
          }
          return (
            <AssignButton
              transactionId={row.transactionId}
              assignRole={Role.SALES_PERSON}
              onDidAssign={reloadList}
            />
          );
        },
        enableSorting: false,
      },
      {
        header: () => <span>Sales Manager</span>,
        accessorKey: "sales-manager",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 216,
        accessorFn: (row) => {
          if (row.salesManager) {
            return <div className="line-clamp-1">{row.salesManager}</div>;
          }
          return (
            <AssignButton
              transactionId={row.transactionId}
              assignRole={Role.SALES_MANAGER}
              onDidAssign={reloadList}
            />
          );
        },
        enableSorting: false,
      },
      {
        header: () => <span>F&I Manager</span>,
        id: "fni-manager",
        cell: (info) => info.getValue(),
        size: 216,
        accessorFn: (row) => {
          if (row.fniManager) {
            return <div className="line-clamp-1">{row.fniManager}</div>;
          }
          return (
            <AssignButton
              transactionId={row.transactionId}
              assignRole={Role.FNI_MANAGER}
              onDidAssign={reloadList}
            />
          );
        },
        enableSorting: false,
      },
      {
        header: () => <span>BDC</span>,
        id: "bdc",
        cell: (info) => info.getValue(),
        size: 216,
        accessorFn: (row) => {
          if (row.bdc) {
            return <div className="line-clamp-1">{row.bdc}</div>;
          }
          return (
            <AssignButton
              transactionId={row.transactionId}
              assignRole={Role.BDC}
              onDidAssign={reloadList}
            />
          );
        },
        enableSorting: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const data = useMemo(() => dataTransform(tableData), [tableData]);

  const table = useReactTable({
    enablePinning: true,
    enableColumnPinning: true,
    data,
    columns,
    state: {
      sorting,
      columnPinning: {
        left: ["creationDate", "firstName", "lastName", "lastActivityDate"],
      },
      columnVisibility: {
        "deal-jacket": canViewDealJacket,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !config.isProduction,
  });
  const numberOfStickyHeaders =
    table
      .getHeaderGroups()
      .at(0)
      ?.headers.filter((h) => h.column.getIsPinned()).length ?? 0;

  //// Filter Section states
  // Filter By Status
  const statuses = TransactionStatusSchema.options.map((status) => ({
    label: getReadableTransactionStatus(status.value),
    value: statusSchemaToGraphQlEnum(status.value),
    key: status.value.toLocaleLowerCase(),
    deprecated: [
      "WEB_LEAD",
      "WEB_APPLICATION",
      "COMPLETED",
      "DISASSOCIATED",
      "DEAD",
      "RULE_90_DAYS",
      "NOT_STARTED",
    ].includes(status.value),
  }));

  const [statusOptions, setStatusOptions] =
    useState<DropdownCheckboxOption<TransactionStatus>[]>(statuses);

  const sourceOptions = TransactionSourceSchema.options.map((source) => ({
    label: getHumanReadableTransactionSource(source.value),
    value: sourceSchemaToGraphQlEnum(source.value),
    key: source.value.toLocaleLowerCase(),
  }));

  const onSelectStatusFilter = (selectedStatus: TransactionStatus[]) => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
    setStatusFilter(selectedStatus);
  };

  const onSelectSourceFilter = (selectedSource: TransactionSource[]) => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
    setSourceFilter(selectedSource);
  };

  const onSearchStatusFilter = (searchString: string) => {
    if (!searchString || !searchString.length) setStatusOptions(statuses); // Reset filter
    const filteredOptions = statuses.filter((option) =>
      option.label.toLowerCase().includes(searchString.toLowerCase())
    );
    setStatusOptions(filteredOptions);
  };

  // Filter by Assigned Staff
  const { data: users } = useQuery(
    userQuery(
      ["BDC", "FNI_MANAGER", "SALES_MANAGER", "SALES_PERSON"],
      {
        pageSize: 100,
        currentPage: 0,
        direction: "after",
      },
      dealership?.activeDealershipPerms.dealershipId,
      false
    )
  );

  const initialStaffOptions = users?.dealership?.users?.edges?.map(
    ({ node }) => {
      return {
        label: `${node?.user?.firstName} ${node?.user?.lastName}`,
        value: node?.user?.id ?? "no-user-id",
        key: node?.user?.id ?? "no-user-id",
      };
    }
  );

  const [staffOptions, setStaffOptions] = useState<
    DropdownCheckboxOption<string>[]
  >([]);

  // This is a quick to prevent rerender loop when the if condition is met
  // This will be reworked into something that does not not involve a useEffect
  useEffect(() => {
    // This prevents empty dropdown
    if (users && staffOptions.length === 0) {
      setStaffOptions(initialStaffOptions ?? []);
    }
  }, [users, staffOptions.length]);

  const onSelectStafFilter = (selectedIds: string[]) => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
    setAssigedStaffIdsFilter(selectedIds);
  };

  const onSearchStaffFilter = (searchString: string) => {
    if (!searchString || !searchString.length)
      setStaffOptions(initialStaffOptions ?? []); // Reset filter
    if (!initialStaffOptions) return;
    const filteredOptions =
      initialStaffOptions.length > 0
        ? initialStaffOptions.filter((option) =>
            option.label.toLowerCase().includes(searchString.toLowerCase())
          )
        : [];
    setStaffOptions(filteredOptions);
  };

  return (
    <div className="flex flex-grow flex-col space-y-4 pb-16">
      {/* The search bar */}
      <div className="flex flex-row w-full space-x-6 items-center group z-20 h-full">
        <TextInput
          onChange={(e) => {
            updateSearchString(e);
          }}
          placeholder="Search"
          iconRight={<MagnifierIcon className="w-4 mr-2 aspect-square" />}
          dataTestId="transaction-search"
        />

        <DropdownCheckbox<TransactionStatus>
          displayFilter={true}
          title={`Status ${statusFilter.length > 0 ? `(${statusFilter.length})` : ""}`.trim()}
          options={statusOptions}
          onSelect={onSelectStatusFilter}
          onSearch={onSearchStatusFilter}
          align="LEFT"
        />

        <DropdownCheckbox<TransactionSource>
          title={`Source ${sourceFilter.length > 0 ? `(${sourceFilter.length})` : ""}`.trim()}
          options={sourceOptions}
          onSelect={onSelectSourceFilter}
          align="LEFT"
        />

        <DropdownCheckbox<string>
          displayFilter={true}
          title={`Staff Member ${assigedStaffIdsFilter.length > 0 ? `(${assigedStaffIdsFilter.length})` : ""}`.trim()}
          options={staffOptions}
          onSelect={onSelectStafFilter}
          onSearch={onSearchStaffFilter}
          align="LEFT"
        />
      </div>

      {loading && (
        <div className="flex relative flex-grow justify-center items-center">
          <Spinner />
        </div>
      )}

      {!loading && (
        <Fragment>
          {/* Main Table */}
          <div
            className="rounded-lg overflow-hidden shadow-md"
            data-test-id="dealer-dashboard-table-body"
          >
            <div className="relative flex flex-grow overflow-scroll">
              <table
                className="table-fixed bg-white"
                {...{
                  style: {
                    width: table.getCenterTotalSize(),
                  },
                }}
              >
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr
                        key={headerGroup.id}
                        className={"border-b border-light-gray"}
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className={classNames("bg-white h-14", {
                                "sticky z-sticky": header.column.getIsPinned(),
                              })}
                              style={{
                                left:
                                  header.column.getSize() *
                                  header.column.getPinnedIndex(),
                                width: header.column.getSize(),
                              }}
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: classNames(
                                      "relative flex w-full h-full px-6 items-center font-medium text-start text-dark-gray",
                                      {
                                        "border-r border-light-gray":
                                          numberOfStickyHeaders - 1 ===
                                          header.index,
                                        "cursor-pointer select-none":
                                          header.column.getCanSort(),
                                      }
                                    ),
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                    colSpan: header.colSpan,
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}

                                  {{
                                    asc: (
                                      <div className="absolute flex top-0 right-4 bottom-0 items-center">
                                        ↑
                                      </div>
                                    ),
                                    desc: (
                                      <div className="absolute flex top-0 right-4 bottom-0 items-center">
                                        ↓
                                      </div>
                                    ),
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        data-test-id={`transaction-row-${row.id}`}
                        key={row.id}
                        className={"cursor-pointer"}
                        onClick={() => {
                          if (row.original.buyerUserId) {
                            navigate(
                              {
                                pathname:
                                  "/dashboard/transaction/:transactionId",
                              },
                              {
                                params: {
                                  transactionId: row.original.transactionId,
                                },
                              }
                            );
                          } else {
                            console.warn("No buyer on the transaction");
                          }
                        }}
                      >
                        {row.getVisibleCells().map((cell, index) => {
                          return (
                            <td
                              key={cell.id}
                              className={classNames("h-14", {
                                "bg-very-light-gray": row.index % 2 === 1,
                                "bg-white": row.index % 2 === 0,
                                "sticky z-sticky": cell.column.getIsPinned(),
                                "border-b border-light-gray":
                                  row.index !== page.pageSize - 1,
                              })}
                              style={{
                                left:
                                  cell.column.getSize() *
                                  cell.column.getPinnedIndex(),
                                width: cell.column.getSize(),
                              }}
                            >
                              <div
                                className={classNames(
                                  "flex items-center px-6 text-dark-gray h-full",
                                  {
                                    "py-0 border-r border-light-gray":
                                      numberOfStickyHeaders - 1 === index,
                                  }
                                )}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-full flex justify-between">
            {/* Explanatory legend */}
            <div className="flex items-center space-x-4 text-dark-gray">
              <div className="inline-flex items-center space-x-2">
                <CheckMarkIcon className="h-6 w-6 text-success" />

                <span className="uppercase">Action Completed</span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <CrossIcon className="h-6 w-6 text-error" />

                <span className="uppercase">None / Invalid</span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <CircleLineThroughIcon className="h-6 w-6 text-gray-300" />

                <span className="uppercase">No data</span>
              </div>
            </div>

            {/* Pagination */}
            <div>
              <div className="flex flex-row space-x-2 items-center text-dark-gray">
                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    { "opacity-40": !(page.currentPage > 1) }
                  )}
                  disabled={!(page.currentPage > 1)}
                  onClick={() => {
                    setPage((p) => ({
                      ...p,
                      direction: "before",
                      cursor: trns[0].cursor,
                      currentPage: p.currentPage - 1,
                    }));
                  }}
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square rotate-180" />
                  </div>
                </button>

                <div className="flex flex-row space-x-2">
                  <div className="flex h-8 px-2 rounded justify-center items-center bg-light-gray">
                    {page.currentPage} / {Math.ceil(totalCount / page.pageSize)}
                  </div>
                </div>

                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    {
                      "opacity-40": !(
                        Math.ceil(totalCount / page.pageSize) > page.currentPage
                      ),
                    }
                  )}
                  disabled={
                    !(Math.ceil(totalCount / page.pageSize) > page.currentPage)
                  }
                  onClick={() => {
                    setPage((p) => ({
                      ...p,
                      direction: "after",
                      cursor: trns[trns.length - 1].cursor,
                      currentPage: p.currentPage + 1,
                    }));
                  }}
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
