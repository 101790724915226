import { useAtomValue } from "jotai";
import { FC, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "~/config";
import { authStateAtom, dealershipAtom } from "~/state";

const LoadAtoms = () => {
  useAtomValue(authStateAtom);
  useAtomValue(dealershipAtom);

  return null;
};

/**
 * This hook changes the fav icon in accordance with the environment we are
 * using.
 */
const FavIconHook = () => {
  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }

    if (config.prodEnv) {
      link.href = `/logo-${config.prodEnv}.png`;
    } else if (!config.isProduction) {
      link.href = `/logo-local.png`;
    }
  }, []);

  return null;
};

/**
 * Error page
 *
 * @param param0
 * @returns
 */
const GlobalErrorFallback: FC<{
  error: any;
  resetErrorBoundary: (...args: unknown[]) => void;
}> = ({ error, resetErrorBoundary }) => {
  return (
    <div
      role="alert"
      className="flex w-full h-full items-center justify-center"
    >
      <div className="h-[400px] w-[400px] shadow-md rounded-md p-4 m-10">
        <h3 className="font-bold">Something went wrong:</h3>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Reset</button>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <ErrorBoundary
      FallbackComponent={GlobalErrorFallback}
      onReset={() => {
        window.location.href = "/";
      }}
      resetKeys={["someKey"]}
    >
      {/* Root Suspense */}
      <Suspense fallback={<div>Loading app</div>}>
        {/* Load atoms first */}
        <LoadAtoms />

        {/* Then do the rest */}
        <FavIconHook />

        <Outlet />

        <ToastContainer position="bottom-right" />
      </Suspense>
    </ErrorBoundary>
  );
}
