import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Selector } from "~/__generated__/backend/zeus";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import FileErrorIcon from "~/components/icons/FileErrorIcon";
import { gqlQueryClient } from "~/lib/backend";
import { Link, useParams } from "~/router";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const documentSelector = Selector("Document")({
  title: true,
  file: {
    url: true,
  },
});

/**
 * It seems like this page is deprecated
 */

const DealJacketDocumentPage = () => {
  const { transactionId, documentId } = useParams(
    "/dashboard/transaction/:transactionId/deal-jacket/document/:documentId"
  );
  const { data } = useQuery({
    queryKey: ["transaction-document", transactionId, documentId],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            buyer: {
              documents: [
                { filter: { id: { equals: documentId } } },
                {
                  edges: {
                    node: documentSelector,
                  },
                },
              ],
            },
            coBuyer: {
              documents: [
                { filter: { id: { equals: documentId } } },
                {
                  edges: {
                    node: documentSelector,
                  },
                },
              ],
            },
            title: true,
          },
        ],
      }),
  });
  const [numPages, setNumPages] = useState(0);
  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const transaction = data?.transaction;

  // We know the id is unique, we just don't know who owns it
  const document =
    transaction?.buyer?.documents?.edges?.at(0) ??
    transaction?.coBuyer?.documents?.edges?.at(0);

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <BreadCrumbsContainer>
          <BreadCrumb title="Transaction">
            <Link to={"/dashboard"}>Transactions</Link>
          </BreadCrumb>

          <BreadCrumb title="User">
            <Link
              to={"/dashboard/transaction/:transactionId"}
              params={{
                transactionId,
              }}
            >
              {transaction?.title}
            </Link>
          </BreadCrumb>

          <BreadCrumb title="Deal Jacket">
            <Link
              to={"/dashboard/transaction/:transactionId/deal-jacket"}
              params={{
                transactionId,
              }}
            >
              Deal Jacket
            </Link>
          </BreadCrumb>

          <BreadCrumb title={document?.node?.title ?? "Document"} />
        </BreadCrumbsContainer>
      </div>

      <div className="flex flex-1 overflow-scroll flex-col items-center">
        <div className="flex flex-col w-full items-center">
          {document?.node?.file?.url ? (
            <Document
              file={document?.node.file?.url}
              onLoadSuccess={handleDocumentLoadSuccess}
              className="flex flex-col space-y-2"
              loading={
                <div className="flex w-full min-h-[80vh] justify-center items-center">
                  <Spinner />
                </div>
              }
              error={
                <div className="flex flex-col w-full min-h-[80vh] justify-center items-center">
                  <div className="flex flex-col space-y-4 items-center">
                    <div className="relative">
                      <FileErrorIcon className="w-20 text-dark-gray" />
                    </div>

                    <div className="text-subtitle text-dark-gray">
                      Failed to load document
                    </div>
                  </div>
                </div>
              }
            >
              {[...new Array(numPages)].map((_, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  className="border border-very-light-gray"
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ))}
            </Document>
          ) : (
            <div className="flex w-1/3 aspect-square items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealJacketDocumentPage;
